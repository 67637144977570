import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'src/components/PrivateRoute';
import WelcomePage from 'src/components/series/WelcomePage.tsx';
import NotFoundPage from 'src/pages/404';

const Welcome = () => (
    <Router>
        <PrivateRoute path="/welcome/phase-and-function" component={WelcomePage} />
        <NotFoundPage default />
    </Router>
);

export default Welcome;
