import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { connect, ConnectedProps } from 'react-redux';
import { getDynamicContent } from 'src/utils/sdk-utils';
import { breakParagraphs } from 'src/utils/miscellanous';
import { checkBetaUserPermit } from 'src/utils/featureFlag';
import { navigate } from 'gatsby';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { notifyBugsnagError } from 'src/utils/bugsnag';
import classNames from 'classnames';
import Selectors from 'src/state/root-selectors';

import { Grid } from 'semantic-ui-react';
import { LinkWithArrow, ProgressBar, Loader, HeadingWithParagraph } from 'src/components/shared';
import { UpdateMyResponses } from '../shared/UpdateMyResponses';
import { EditPhasesParams } from 'src/components/shared/extra-content/templates/PnfMyPlan';
import * as Styles from 'src/styles/welcome.module.scss';

import { MY_PLAN_PNF_PATH } from 'src/constants/url-constants';

interface IPhaseAttributes {
    [x: string]:
        | {
              cycle: any;
              current: string;
              currentDay: number;
              currentPhaseDay: number;
              currentDate: string;
              hasNegativePhase: boolean;
              phases: {
                  name: string;
                  days: any;
                  start: {
                      day: number;
                      date: string;
                  };
                  end: {
                      day: any;
                      date: string;
                  };
              }[];
          }
        | undefined;
}

const connector = connect((state: IState) => ({
    phaseAttributes: Selectors.phases.currentPhases(state),
    variants: Selectors.flags.phaseAndFunction(state),
}));

const WelcomePage = ({ phaseAttributes, variants }: ConnectedProps<typeof connector>) => {
    const [phaseContentState, setPhaseContentState] = useState('');
    const [gettingStartedContent, setGettingStartedContent] = useState<any[]>([]);
    const [loadingState, setLoadingState] = useState(true);
    const [betaUser, setBetaUser] = useState(false);
    const isMobile = useBreakpoints(Breakpoints.lg, Directions.down);
    const isDesktop = useBreakpoints(Breakpoints.xxl, Directions.up);

    useEffect(() => {
        async function getWelcomePageContent(currentPhase: string) {
            try {
                const content = await getDynamicContent('PhaseAndFunction.Onboarding');
                if (content && content.length > 0) {
                    const currentPhaseContent = content.find((e: { key: string }) =>
                        RegExp(currentPhase, 'i').test(e.key)
                    );
                    const gettingStartedContent = content.filter((e: { key: string }) =>
                        RegExp('GettingStarted', 'i').test(e.key)
                    );
                    setPhaseContentState(currentPhaseContent.value);
                    setGettingStartedContent(gettingStartedContent);
                }
            } catch (error) {
                notifyBugsnagError(error);

                console.log({ error });
                return {};
            }
        }

        if (!!variants.phaseAndFunctionSeries) {
            if (checkBetaUserPermit(variants)) {
                setBetaUser(true);
                if (!!phaseAttributes) {
                    getWelcomePageContent(phaseAttributes.current);
                    setLoadingState(false);
                }
            } else {
                setLoadingState(false);
            }
        }
    }, [phaseAttributes, variants]);

    if (loadingState) {
        return (
            <div className={Styles.welcomePageWrapper}>
                <Loader />
            </div>
        );
    }

    if (!betaUser) {
        navigate('/404');
        return null;
    }

    return (
        <div className={Styles.welcomePageWrapper}>
            <Grid className={Styles.gridContent}>
                <Grid.Column
                    className={Styles.progressBarColumn}
                    verticalAlign="middle"
                    computer={8}
                    mobile={16}
                >
                    {isMobile && (
                        <div className="margin-bottom--xlarge">
                            <FirstDetailsRow phaseAttributes={phaseAttributes} />
                        </div>
                    )}
                    <ProgressBar phaseAttributes={phaseAttributes} vertical />
                </Grid.Column>
                <Grid.Column className={Styles.detailsColumn} computer={8} mobile={16}>
                    {!isMobile && <FirstDetailsRow phaseAttributes={phaseAttributes} />}
                    <SecondDetailsRow
                        phaseContent={phaseContentState}
                        phaseContentClass={isDesktop ? 'p1' : 'p2'}
                    />
                    <MoreAboutRow phaseAttributes={phaseAttributes} />
                    <GettingStartedRow text={gettingStartedContent} />
                </Grid.Column>
            </Grid>
        </div>
    );
};

const FirstDetailsRow = ({ phaseAttributes }: IPhaseAttributes) => (
    <Grid.Row className={Styles.firstDetailsRow}>
        <p>
            {`You're on day ${phaseAttributes?.currentDay} of your ${phaseAttributes?.cycle} day cycle which means you're in the`}
        </p>
        <div className="justifyContent-space-between">
            <span
                className={`border-color-pv-${phaseAttributes?.current} ${Styles.currentPhaseUnderline} h3`}
            >
                {`\n${phaseAttributes?.current} phase`}
            </span>
            <UpdateMyResponses
                to={`phase-and-function/${EditPhasesParams.EDIT_MY_CYCLE}`}
                iconSize={26}
            />
        </div>
    </Grid.Row>
);

interface ISecondDetailsRow {
    phaseContent: string;
    phaseContentClass: string;
}

const SecondDetailsRow = ({ phaseContent, phaseContentClass }: ISecondDetailsRow) => (
    <Grid.Row className={Styles.secondDetailsRow}>
        {!!phaseContent && breakParagraphs(phaseContent, phaseContentClass)}
    </Grid.Row>
);

const MoreAboutRow = ({ phaseAttributes }: IPhaseAttributes) => (
    <Grid.Row className={Styles.moreAboutRow}>
        <p>
            More about the{' '}
            <LinkWithArrow
                to={`/more-about/${phaseAttributes?.current}`}
                label={`${phaseAttributes?.current} phase`}
                className="display-inline-flex"
            />
        </p>
    </Grid.Row>
);

interface IGettingStartedRow {
    text: any[];
}

const GettingStartedRow = ({ text }: IGettingStartedRow) => {
    const heading = text.find((e) => /title/i.test(e.key));
    const paragraph = text.find((e) => /description/i.test(e.key));
    const isDesktop = useBreakpoints(Breakpoints.xxl, Directions.up);

    return (
        <Grid.Row className={Styles.gettingStartedRow}>
            <HeadingWithParagraph
                headingLabel={heading?.value}
                headingSize={isDesktop ? 'h2' : 'h3'}
                headingWeight="bold"
                headingClass="margin-bottom--small"
                pClass={isDesktop ? 'p1' : 'p2'}
                headingTransform="upper"
                pLabel={paragraph?.value}
                alignment="vertical"
            />

            <Button
                className={classNames(Styles.goToMyPlanButton, 'alignItems--center')}
                as={Link}
                to={MY_PLAN_PNF_PATH}
            >
                Go to Home
            </Button>
        </Grid.Row>
    );
};

export default connector(WelcomePage);
