// extracted by mini-css-extract-plugin
export const mini = "welcome-module--mini--23tBa";
export const tiny = "welcome-module--tiny--3I2Rt";
export const small = "welcome-module--small--1hC0c";
export const medium = "welcome-module--medium--3yZNJ";
export const p3 = "welcome-module--p3--2zbDE";
export const large = "welcome-module--large--1YCdi";
export const p2 = "welcome-module--p2--Sk3D1";
export const l1 = "welcome-module--l1--3GV0j";
export const label = "welcome-module--label--1C_EJ";
export const big = "welcome-module--big--3_zsR";
export const p1 = "welcome-module--p1--3JB5j";
export const huge = "welcome-module--huge--29Sf0";
export const p0 = "welcome-module--p0--1D58v";
export const huge36 = "welcome-module--huge36--2t0nJ";
export const huge52 = "welcome-module--huge52--3BrSk";
export const massive = "welcome-module--massive--nfzD1";
export const h1 = "welcome-module--h1--3RSR3";
export const h2 = "welcome-module--h2--14Cf_";
export const h3 = "welcome-module--h3--2CMr6";
export const h4 = "welcome-module--h4--3EBDE";
export const h5 = "welcome-module--h5--10WBu";
export const h6 = "welcome-module--h6--1tN95";
export const accent = "welcome-module--accent--1w3GQ";
export const accenth5 = "welcome-module--accenth5--mbfFO";
export const accenth6 = "welcome-module--accenth6--OsMVj";
export const upper = "welcome-module--upper--3TRy2";
export const lower = "welcome-module--lower--3Q3KF";
export const underline = "welcome-module--underline--2f0GW";
export const bold = "welcome-module--bold--2QA71";
export const semibold = "welcome-module--semibold--ZlKR_";
export const normal = "welcome-module--normal--39q62";
export const stacked = "welcome-module--stacked--2Js16";
export const link = "welcome-module--link--28mJP";
export const textLinks = "welcome-module--text-links--2i8Xv";
export const welcomePageWrapper = "welcome-module--welcome-page-wrapper--348Qp";
export const gridContent = "welcome-module--grid-content--2BjS1";
export const progressBarColumn = "welcome-module--progress-bar-column--ZVM_o";
export const firstDetailsRow = "welcome-module--first-details-row--_dQp1";
export const currentPhaseUnderline = "welcome-module--current-phase-underline--3DBPs";
export const detailsColumn = "welcome-module--details-column--296KM";
export const secondDetailsRow = "welcome-module--second-details-row--2x0fD";
export const moreAboutRow = "welcome-module--more-about-row--3MVSe";
export const gettingStartedRow = "welcome-module--getting-started-row--1jXBq";
export const editMyCycle = "welcome-module--edit-my-cycle--3JYEK";
export const goToMyPlanButton = "welcome-module--go-to-my-plan-button--1Yco2";